import { Component, OnInit } from '@angular/core';
import { environment } from '@Environments';
import { GoogleAnalyticsService } from '@App/common/services/google-analytics.service';

@Component({
  selector: 'app-bootstrap',
  template: '<router-outlet></router-outlet>'
})
export class BootstrapComponent implements OnInit {
  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    // Initialize Google Analytics
    if (environment.production) {
      this.googleAnalyticsService.initialize();
    }
  }
}
